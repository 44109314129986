import jQuery from "jquery";
import Flickity from "flickity";
import "flickity-imagesloaded";
import "jquery-match-height";

jQuery(document).ready(function ($) {
  $(".mobile-menu").hide();
  $(".toggle-mobile-menu").click(function() {
    $('.mobile-menu').slideToggle('fast');
  });

  $(".mobile-menu-link").click(function() {
    $('.mobile-menu').slideUp('fast');
  })


  $(document).on('click', 'a[href^="#"]', function (event) {
      event.preventDefault();

      $('html, body').animate({
          scrollTop: $($.attr(this, 'href')).offset().top
      }, 500);
  });

  if($('.services-flkty').length) {
    const $services_slider = document.querySelector('.services-flkty');
    const services_flkty = new Flickity ($services_slider, {
        cellAlign: "left",
        wrapAround: false,
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        watchCSS: true,
    })
  }

  if($('.values-flkty').length) {
    const $values_slider = document.querySelector('.values-flkty');
    const values_flkty = new Flickity ($values_slider, {
        cellAlign: "left",
        wrapAround: false,
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        watchCSS: true,
    })
  }
});
